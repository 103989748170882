<template>
    <div class="companyInvite">
        <teleport v-if="mountedComponent" to="#header-nav">
            <CBreadcrumbItem>
                <router-link :to="{ name: 'CompaniesList' }">Companies</router-link>
            </CBreadcrumbItem>

            <CBreadcrumbItem active> Add & Invite</CBreadcrumbItem>
        </teleport>

        <CCard>
            <CCardBody class="p-xl-4 p-3">
                <CRow>
                    <CCol class="companyInvite__leftSide">
                        <Wrap :title="'Add Existing Company'">
                            <div
                                class="d-lg-flex d-block w-100 justify-content-between align-items-center text-center text-lg-start">
                                <CFormLabel class="companyInvite__label">Search for Company:</CFormLabel>

                                <CInputGroup :style="{ maxWidth: '300px' }">
                                    <CSelectSearch v-model="search" :label="'Search'" :options="searchList"
                                                   :placeholder="'Company name, DBA, email'" :search="true"
                                                   :visible-not-found="false" style="width: 100%" :loading="loadingList"
                                                   :view-all="true" @fetchOptions="changeSearch"/>
                                </CInputGroup>
                            </div>

                            <div class="d-lg-flex companyInvite__addCompany">
                                <div class="companyInvite__logo">
                                    <img v-if="logo" :src="logo" class="companyInvite__logo-img"/>
                                    <template v-else>Logo</template>
                                </div>

                                <div class="companyInvite__fields">
                                    <div class="companyInvite__fields-item">
                                        <h3 class="d-lg-block d-none companyInvite__fields-heading companyInvite__heading">
                                            Add Existing Company
                                        </h3>

                                        <p style="width: 100%" class="companyInvite__label">
                                            Company Name:
                                        </p>

                                        <h5>{{ name || '-' }}</h5>
                                    </div>

                                    <div class="companyInvite__fields-item">
                                        <p style="width: 100%" class="companyInvite__label">
                                            Website:
                                        </p>

                                        <h5>{{ website || '-' }}</h5>
                                    </div>

                                    <div class="companyInvite__fields-item">
                                        <p style="width: 100%" class="companyInvite__label">DBA:</p>

                                        <h5>{{ dba || '-' }}</h5>
                                    </div>

                                    <div class="companyInvite__fields-item">
                                        <p style="width: 100%" class="companyInvite__label">
                                            Tax ID / EIN:
                                        </p>

                                        <h5>{{ tax || '-' }}</h5>
                                    </div>
                                </div>
                            </div>

                            <div class="d-lg-flex d-block w-100 justify-content-between companyInvite__buttons">
                                <CButton class="m-3 ml-0 d-block w-xl-100 w-lg-100" color="primary"
                                         :disabled="!company_id" @click="add(true)">
                                    Add & go to Сompanies
                                </CButton>

                                <CButton class="m-3 ml-0 d-block w-xl-auto w-lg-100" color="primary"
                                         :disabled="!company_id" @click="add()">
                                    Add
                                </CButton>

                                <CButton class="m-3 ml-0 mr-0 d-block w-xl-auto w-lg-100" color="primary"
                                         variant="outline" @click="cancel">
                                    Cancel
                                </CButton>
                            </div>
                        </Wrap>
                    </CCol>

                    <CCol class="companyInvite__rightSide">
                        <div class="d-flex flex-column h-100 justify-content-end companyInvite__rightSide-inner">
                            <Wrap :title="'Invite New Company or User'"
                                  class-names="d-lg-flex flex-column flex-grow-1 companyInvite__newCompany">
                                <div class="companyInvite__fields">
                                    <h3 class="d-lg-block d-none companyInvite__fields-heading companyInvite__heading">
                                        Invite New Company or User
                                    </h3>

                                    <div class="companyInvite__fields-item">
                                        <CFormLabel style="width: 100%" class="companyInvite__label">
                                            Contact Email
                                        </CFormLabel>

                                        <CInputGroup>
                                            <CFormInput v-model="invitation_email" @input="onInputChange"
                                                        placeholder="Enter Contact Email"
                                                        :invalid="!!errors.invitation_email"
                                                        @blur="() => validate('invitation_email')"/>
                                        </CInputGroup>

                                        <CFormText v-if="errors.invitation_email"
                                                   :style="`color: ${errors.invitation_email && 'red'}`">
                                            {{ errors.invitation_email }}
                                        </CFormText>
                                    </div>

                                    <div class="companyInvite__fields-item">
                                        <CFormLabel style="width: 100%" class="companyInvite__label">
                                            Company Name
                                        </CFormLabel>

                                        <CInputGroup>
                                            <CFormInput v-model="invitation_company_name" @input="onInputChange"
                                                        placeholder="Enter Company Name"
                                                        :invalid="!!errors.invitation_company_name"
                                                        @blur="() => validate('invitation_company_name')"/>
                                        </CInputGroup>

                                        <CFormText v-if="errors.invitation_company_name"
                                                   :style="`color: ${errors.invitation_company_name && 'red'}`">
                                            {{ errors.invitation_company_name }}
                                        </CFormText>
                                    </div>
                                </div>

                                <template v-if="find_company">
                                    <div class="mt-3 d-lg-flex align-items-center">
                                        <div class="m-2 ml-0 w-lg-100 companyInvite__text">
                                            Do you want to add Company
                                            <router-link target="_blank"
                                                         :to="{ name: 'CompanyInfo', params: { id: find_company.id } }">
                                                <u>{{ find_company.name }}</u>
                                            </router-link>
                                            ?
                                        </div>

                                        <div class="d-lg-flex d-block flex-shrink-0">
                                            <CButton class="m-2 ml-0 d-block w-xl-auto w-lg-100" color="primary"
                                                     @click="setCompany">
                                                Yes
                                            </CButton>
                                        </div>
                                    </div>
                                </template>

                                <div class="mt-2 companyInvite__text">
                                    This will send an invitation via email to sign-up and join the
                                    Contracts Connected network of professionals. The user you
                                    send this to will need to either create a new company or join
                                    an existing company. Once they complete the sign-up process,
                                    their company will be added to your private list of companies.
                                </div>

                                <div class="companyInvite__buttons companyInvite__buttons--mt-a">
                                    <CButton class="w-xl-auto w-lg-100 m-3 ml-0 mr-0" color="primary"
                                             :disabled="!invitation_email || !invitation_company_name || !!errors.invitation_email || companyFound"
                                             @click="checkDataAndSend">
                                        Send
                                    </CButton
                                    >
                                </div>
                            </Wrap>
                        </div>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>

        <CModal content-class-name="companyInvite__prefill-modal prefill-modal" size="lg" :visible="prefillModal"
                @close=" closePrefillModal">
            <CModalBody>
                <h3 class="prefill-modal__heading">Invite company</h3>

                <CForm class="prefill-modal__form">
                    <div class="prefill-modal__row prefill-modal__group">
                        <div class="prefill-modal__field">
                            <CInputGroup>
                                <CFormInput v-model="invitation_company_name" placeholder="Company Name"
                                            size="lg" :invalid="!!errors.invitation_company_name"
                                            @blur="() => validate('invitation_company_name')"/>
                            </CInputGroup>

                            <CFormText v-if="errors.invitation_company_name"
                                       :style="`color: ${errors.invitation_company_name && 'red'}`">
                                {{ errors.invitation_company_name }}
                            </CFormText>
                        </div>

                        <div class="prefill-modal__field">
                            <CInputGroup>
                                <CFormInput v-model="invitation_company_dba" placeholder="DBA" size="lg"/>
                            </CInputGroup>
                        </div>
                    </div>

                    <div class="prefill-modal__row prefill-modal__group">
                        <div class="prefill-modal__field">
                            <CInputGroup>
                                <CFormInput v-model="invitation_company_website" placeholder="www.mycompany.com"
                                            size="lg" :invalid="!!errors.invitation_company_website"
                                            @blur="() => validate('invitation_company_website')"/>
                            </CInputGroup>
                            <CFormText v-if="errors.invitation_company_website"
                                       :style="`color: ${errors.invitation_company_website && 'red'}`">
                                {{ errors.invitation_company_website }}
                            </CFormText>
                        </div>
                        <div class="prefill-modal__field">
                            <CInputGroup>
                                <CFormInput v-model="invitation_company_tid" v-mask="['##-#######']"
                                            placeholder="Tax ID / EIN" size="lg"
                                            :invalid="!!errors.invitation_company_tid"
                                            @blur="() => validate('invitation_company_tid')"/>
                            </CInputGroup>

                            <CFormText v-if="errors.invitation_company_tid"
                                       :style="`color: ${errors.invitation_company_tid && 'red'}`">
                                {{ errors.invitation_company_tid }}
                            </CFormText>
                        </div>
                    </div>
                </CForm>
            </CModalBody>

            <CModalFooter class="prefill-modal__buttons">
                <CButton class="prefill-modal__button" color="primary" variant="outline" @click="closePrefillModal">
                    Decline
                </CButton>

                <CButton color="primary" class="prefill-modal__button" :disabled="!invitation_company_name"
                         @click="send">
                    Send invite
                </CButton>
            </CModalFooter>
        </CModal>
    </div>
</template>

<script>
import ImageInput from '@/components/ImageInput'
import errors from '@/mixin/errors'
import Wrap from './Wrap'
import modalComponent from '@/mixin/modalComponent'
import {mask} from 'vue-the-mask'
import CSelectSearch from '@/components/CSelectSearch'
import CompanyInvitations from "@/api/v2/endpoints/CompanyInvitations";
import apiErrorHandler from "@/mixin/apiErrorHandler";

export default {
    inject: ['toast'],
    directives: {mask},
    name: 'CompanyInvite',
    components: {ImageInput, Wrap, CSelectSearch},
    mixins: [errors, modalComponent, apiErrorHandler],
    data() {
        return {
            mountedComponent: false,
            find_company_email: null,
            search: '',
            searchList: [],
            prefillModal: false,

            company_id: null,
            logo: null,
            name: '',
            website: '',
            dba: '',
            tax: '',
            company_name: '',

            find_company: null,

            errors: {},
            loadingList: false,

            invitation_email: null,
            invitation_company_name: null,
            invitation_company_website: null,
            invitation_company_dba: null,
            invitation_company_tid: null,

            ruleFields: [
                {
                    key: 'invitation_email',
                    rules: [
                        {name: 'max', params: 255},
                        {name: 'email'},
                        {name: 'required'},
                    ],
                },
                {
                    key: 'invitation_company_name',
                    rules: [{name: 'max', params: 255}, {name: 'required'}],
                },
                {
                    key: 'invitation_company_dba',
                    rules: [{name: 'max', params: 255}],
                },
                {
                    key: 'invitation_company_website',
                    rules: [{name: 'max', params: 255}, {name: 'website'}],
                },
            ],
        }
    },
    computed: {
        companyFound() {
            return this.find_company && this.find_company_email
        }
    },
    watch: {
        search() {
            const find = this.searchList.find((item) => item.value === this.search)
            if (find && find.info) {
                this.company_id = find.value
                this.logo = find.info.logo
                this.name = find.info.company_name
                this.website = find.info.website
                this.dba = find.info.dba
                this.tax = find.info.tid
            } else {
                this.company_id = null
                this.logo = null
                this.name = ''
                this.website = ''
                this.dba = ''
                this.tax = ''
            }
        },
    },
    mounted() {
        this.mountedComponent = true;
        this.changeSearch();
    },
    methods: {
        onInputChange() {
            this.find_company = null
            this.find_company_email = null
        },
        findCompany(data) {
            this.find_company = null
            return this.$http.companies.findCompanyEmail({params: data})
        },
        setCompany() {
            this.company_id = this.find_company.id
            this.logo = this.find_company.logo
            this.name = this.find_company.name
            this.website = this.find_company.website
            this.dba = this.find_company.dba
            this.tax = this.find_company.tid
        },
        changeSearch(s = '') {
            if (s) {
                this.loadingList = true
                this.$http.companies
                    .fetchNewCompany({params: {search: s}})
                    .then((res) => {
                        this.loadingList = false
                        this.searchList = res.data.data.map((item) => ({
                            value: item.company_id,
                            label: item.company_name,
                            info: item,
                        }))
                    })
                    .catch(() => {
                        this.loadingList = false
                    })
            } else {
                this.searchList = []
            }
        },
        add(red = false) {
            this.$http.companies
                .addCompanyById({company_id: this.company_id})
                .then(() => {
                    this.toast('info', 'The company has been added to your list successfully')
                    this.company_id = null
                    this.logo = null
                    this.name = ''
                    this.website = ''
                    this.dba = ''
                    this.tax = ''
                    if (red) {
                        this.$router.push({name: 'CompaniesList'})
                    }
                })
                .catch(({response}) => {
                    this.toast('warning', response.data.message)
                })
        },
        cancel() {
            this.search = null
        },
        send() {
            CompanyInvitations
                .store(this.defineCompanyInvitationData())
                .then(() => {
                    this.toast('info', 'The invitation has been sent')
                    this.invitation_company_name = null;
                    this.invitation_email = null;
                    this.closePrefillModal();
                })
                .catch(response => this.handleApiError(response))
        },
        defineCompanyInvitationData() {
            return {
                email: this.invitation_email,
                company: {
                    name: this.invitation_company_name,
                    dba: this.invitation_company_dba,
                    website: this.invitation_company_website,
                    tid: this.invitation_company_tid,
                }
            };
        },
        openPrefillModal() {
            this.$modal
                .open({
                    close: 'No',
                    success: 'Yes',
                    label: 'Do you want to pre-fill Company Info for user invite?',
                    size: 'lg',
                })
                .then(() => {
                    this.$modal.close()
                    this.prefillModal = true
                })
                .catch(() => {
                    this.send()
                    this.$modal.close()
                })
        },
        closePrefillModal() {
            this.prefillModal = false;
            this.invitation_company_website = null;
            this.invitation_company_dba = null;
            this.invitation_company_tid = null;
        },
        checkDataAndSend() {
            if (this.find_company && !this.find_company_email) {
                return this.openPrefillModal()
            }

            this.findCompany({
                email: this.invitation_email,
                company_name: this.invitation_company_name,
            })
                .then(res => {
                    this.find_company = res.data.company
                    this.find_company_email = res.data.email_exist

                    if (this.find_company && this.find_company_email) {
                        this.errors = this.getErrors({
                            email: ['The email address is already taken']
                        })
                    }

                    if (!this.find_company && !this.find_company_email) {
                        this.openPrefillModal()
                    }
                })
                .catch(error => {
                    const res = error.response
                    this.errors = res.data.errors ? this.getErrors(res.data.errors) : {}
                })
        },
    }
}
</script>

<style lang="scss">
@import 'style';
</style>
